<template>
    <RoundInput
		v-bind="$attrs"
		v-mask="$maskPhoneFormatCode"
		placeholder="Телефон"
		v-model="VModelValue"
		:clear-btn="clearBtn"
		@clear="clear"
	/>
</template>

<script>
import RoundInput from "~/components/_elements/roundInputCollection/roundInput";

export default {
    name: "roundPhoneInput",
    components: {RoundInput},
    inheritAttrs: false,
    props: {
        value: {},
        clearBtn: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
      VModelValue: {
        set(value) {
          if(value && (value.startsWith('+9') || value.startsWith('9'))) {
            value = `+7${value.replace('+', '').replace(/ /g, '')}`;
          }
          this.$emit('input', this.mask(value));
          if(value?.length > this.VModelValue?.length) {
              this.$forceUpdate();
          }
        },
        get() {
          return this.value?.replace?.('+8', '8');
        }
      }
    },
  methods: {
      mask(value) {
        return this.$filters.VMask(value, this.$maskPhoneFormatCode);
      },
	  clear() {
        this.$emit('input', '');
	  }
  }
}
</script>

<style lang="scss">
.round-input {
    width: 100%;
    margin-bottom: 0;

    &.confirmed input {
        padding-right: 50px;

        background-image: url("~@/assets/img/icons/input-confirm.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 18px) 50%;
    }

    &.large input{
        height: 60px;
        font-size: 18px;
        border-radius: 10px;

        &:placeholder {
            font-size: 18px;
        }


        @include mobile {
            height: 48px;
            border-radius: 8px;
        }
    }

    &.large80 input {
        height: 80px;
        width: 64px;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        @include mobile {
            height: 60px;
            width: 43px;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
        }
    }

    input {
        @include roundInput;
        cursor: text;
    }
}
</style>
