import { mapActions, mapMutations, mapState } from "vuex";
import checkNotAuth from "@/mixins/user/checkNotAuth";
import { getReferralId, getUtm } from "@/utils/router";
import { cookieNames } from '~/utils/_consts';

export default {
    mixins: [checkNotAuth],
    middleware: 'auth',
    auth: 'guest',
    computed: {
        errors() {
            const err = this.$store.state.profile.error;
            return err.authCodeResult?.errors || err.authCodeValidateResult?.errors || [];
        },
        pending() {
            return Object.values(this.$store.state.profile.pending).some(v => v);
        },
        ...mapState('promotions', ['activateNewUserPromo']),
    },
    methods: {
        auth(nextPage = false, confirmed = false) {
            const method = this.phone ? 'phone' : 'email';
            const params = { method };
            if (confirmed) {
                params.client_info = {
                    supplied_audi: 1,
                }
            }
            const data = {
                [method]: this[method],
                source_info: {
                    ...getUtm(),
                    source_signup: this.$store.state.sourceSignup,
                },
                referral_id: getReferralId(),
            };

            if (this.activateNewUserPromo) {
                data.ab = 'newbies_bonus';
            }

            this.authCode({
                params,
                data,
            }).then(({ data }) => {
                if (nextPage) {
                    this.$router.replace({ name: nextPage });
                }
                if (data?.settings?.new_registration) {
                    this.$signals.signUp(method, data?.id, this.$store.state.sourceSignup);
                }
                return data;
            }, (e) => this.$notifySrvErr('Ошибка авторизации', e));

            if (this.phone) {
                this.$signals.authClickGetSmsCode();
            } else {
                this.$signals.authClickGetEmailCode();
            }
        },
        codeValidate(method, code, login) {
            this.authCodeValidate({
                params: { method },
                data: {
                    [method]: login,
                    code,
                    source_info: getUtm(),
                },
            }).then(({ data: { access_token, ...user } }) => {
                this.$auth.setUser(user);
                this.$auth.setUserToken(access_token);

                if (user?.settings?.new_registration) {
                    if (user?.available_bonus_points > 0) {
                        this.$cookies.set(cookieNames.bonusSignUp, '1', { path: '/' });
                    }
                } else {
                    this.$signals.login(method);
                }
                if (user.is_password_set === false) {
                    this.$router.replace({ name: 'sign-in-passwordCreate' });
                }
                this.$signals.authCodeSuccess();
            }, () => {
                this.$signals.authCodeFailed();
            });
        },
        ...mapMutations('modal', ['openModal']),
        ...mapActions('profile', ['authCode', 'authCodeValidate']),
    },
}
