<template>
    <div class="page-auth-phone">
        <form class="auth-container" @submit.prevent="auth('sign-in-phoneConfirm', confirmed)">
            <AuthTitle title="Введите телефон" />
            <RoundPhoneInput autocomplete="tel" v-model="phone" :errors="errors.phone" large required />
            <AudiConfirmProcessingPersonalDataCheckbox
                v-if="isAudiTemplateVisited"
                v-model="confirmed"
            />
            <AuthTerms v-model="terms" />
            <button type="submit" class="primary large full" :disabled="!terms || pending">Получить код</button>
            <AuthChangeLink label="Войти через пароль" link-name="sign-in-password"
                            @click.native="$signals.authClickSignInByEmail" />
        </form>
    </div>
</template>

<script>
import AuthTitle from "~/components/auth/AuthTitle";
import AuthChangeLink from "~/components/auth/AuthChangeLink";
import RoundPhoneInput from "~/components/_elements/roundInputCollection/roundPhoneInput";
import authCodeMixin from "@/mixins/user/authCodeMixin";
import { LOCALSTORAGE_NAMES } from "@/utils/_consts";
import dynamicAuthLayout from "@/mixins/auth/dynamicAuthLayout";
import isAudiTemplateVisited from "@/mixins/cookie/isAudiTemplateVisited";
import AudiConfirmProcessingPersonalDataCheckbox
    from "@/components/brands/audi/AudiConfirmProcessingPersonalDataCheckbox";
import AuthTerms from '@/components/auth/AuthTerms';

export default {
    name: "sign-in",
    components: {
        AuthTerms,
        AudiConfirmProcessingPersonalDataCheckbox, RoundPhoneInput, AuthChangeLink, AuthTitle,
    },
    mixins: [authCodeMixin, dynamicAuthLayout, isAudiTemplateVisited],
    beforeRouteEnter(to, from, next) {
        if (process.client) {
            localStorage.setItem(LOCALSTORAGE_NAMES.lastRoute, from.fullPath);
        }
        next();
    },
    data: () => ({
        terms: false,
        confirmed: false,
        phone: '',
    }),
}
</script>

<style lang="scss">
.page-auth-phone {
    .auth-container {
        .round-input {
            margin-bottom: 16px;
        }

        button.primary {
            margin-bottom: 24px;
        }
    }
}
</style>
