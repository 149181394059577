<template>
    <div class="auth-terms-block" @click="$emit('change', !active)">
        <Checkmark :active="active" />
        <input type="checkbox" class="auth-terms-block__input" v-model="model" required>
        <span class="auth-terms-block__info">
            Согласен (-на) с
            <a class="offer" href="/docs/Oferta_RentRide.pdf" target="_blank">условиями оферты</a>
            и&nbsp;с&nbsp;<a class="offer" href="/docs/consent_personal_data.pdf" target="_blank">обработкой персональных данных</a>
        </span>
    </div>
</template>

<script>
import Checkmark from '@/components/_elements/form/checkmark';

export default {
    model: Checkmark.model,
    name: "AuthTerms",
    components: { Checkmark },
    props: {
        active: Boolean,
    },
    computed: {
        model: {
            set(value) {
                this.$emit('change', value)
            },
            get() {
                return this.active;
            },
        },
    },
}
</script>

<style lang="scss">
.auth-terms-block {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    margin-bottom: 24px;

    &__info {
        margin-left: 12px;
    }

    &__input {
        @include inputHide;
    }

    a {
        color: $primary;
    }
}
</style>
